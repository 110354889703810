import react, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "../../../../assets/css/custom.css";
import customFilterMethod from "helpers/customFilterMethod.js";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";

export default function ShowTechnicienTab() {
  const nav = useNavigate();
  const token = localStorage.getItem("token");
  const params = useParams();
  const API_URL = "https://acquaback.shenron.dev/api/";
  let CONFIG_ = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  const localization = {
    previousText: "Précédent",
    nextText: "Suivant",
    pageText: "Page",
    ofText: "sur",
    rowsText: "lignes",
  };

  const [clientBelongTo, setClientBelongTo] = useState("");

  useEffect(() => {
    axios
      .get(API_URL + `showChildListBelongToParent/${params.id}`, CONFIG_)
      .then((res) => setClientBelongTo(res.data))
      .catch((err) => console.error(err));
  }, []);

  const addParamToUrl = (event) => {
    nav("/showReseller/" + event.currentTarget.id);
  };
  const columns = [
    {
      Header: () => <div style={{ textAlign: "center" }}>ID</div>,
      accessor: (d) => d.id,
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>NOM</div>,
      accessor: (d) => `${d.name}  ${d.firstname}`,
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>LIEU</div>,
      accessor: (d) => d.country,
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>STATUT</div>,
      accessor: (d) => d.state,
      Cell: ({ value }) => (
        <div
          style={{ color: value === 1 ? "green" : "red", textAlign: "center" }}
        >
          {value === 1 ? "actif" : "inactif"}
        </div>
      ),
    },
  ].map((col, i) => {
    col.id = `${i}`;
    return col;
  });

  const onRowClick = (state, rowInfo, column, instance) => {
    return {
      onClick: (e) => {
        nav(`/showUser/${rowInfo.original.id}`); // ou show user
      },
    };
  };

  return (
    <>
      <Row className="CenterAll">
        <div className="containertitle">
          <h2 className="titlePage">LISTE DES CLIENTS</h2>
        </div>
      </Row>
      <Row className="CenterAll">
        <Col lg="12">
          <Card className="mt-5">
            <Col lg="12" className="d-flex justify-content-center">
              <Card>
                <CardBody>
                  {clientBelongTo ? (
                    <ReactTable
                      filterable
                      defaultFilterMethod={customFilterMethod}
                      getTrProps={onRowClick}
                      value={clientBelongTo?.id}
                      data={clientBelongTo}
                      columns={columns}
                      defaultPageSize={10}
                      previousText={localization.previousText}
                      nextText={localization.nextText}
                      pageText={localization.pageText}
                      ofText={localization.ofText}
                      rowsText={localization.rowsText}
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  );
}
