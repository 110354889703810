import React, { useState } from 'react';
import UserAddForm from './userAddForm';
import TechnicienAddForm from './technicienAddForm';
import DistributeurAddForm from './distributeurAddForm';
import RevendeurAddForm from './resellerAddForm'
import { useParams } from 'react-router-dom';
export default function UserAdd() {
    const params = useParams();

    return (
        <div>
            {params.id == 5 && (<UserAddForm role_id={params.id} />)}
            {params.id == 4 && (<TechnicienAddForm/>)}
            {params.id == 3 && (<RevendeurAddForm/>)}
            {params.id == 2 && (<DistributeurAddForm/>)}
        </div>
    );
}