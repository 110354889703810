import React, { useState } from "react";
import "../Sidebar/Sidebar.css";
import Adminbar from "../Sidebar/Adminbar.js";
import Clientbar from "../Sidebar/Clientbar.js";
import Techbar from "../Sidebar/Techbar.js";
import Distribbar from "../Sidebar/Distribbar.js";
import Resellbar from "../Sidebar/Resellbar.js";
import { useNavigate } from "react-router-dom";
export default function Sidebar() {
  const [loggedUser, setLoggedUser] = useState(
    JSON.parse(localStorage.getItem("loggedUser"))
  );
  const user_params_id = localStorage.getItem("user_params_id");
  let sidebar = null;

  if (loggedUser && loggedUser.role_id) {
    switch (loggedUser.role_id) {
      case 1:
        sidebar = <Adminbar loggedUser={loggedUser} userId={user_params_id} />;
        break;
      case 2:
        sidebar = (
          <Distribbar loggedUser={loggedUser} userId={user_params_id} />
        );
        break;
      case 3:
        sidebar = <Resellbar loggedUser={loggedUser} userId={user_params_id} />;
        break;
      case 4:
        sidebar = <Techbar loggedUser={loggedUser} userId={user_params_id} />;
        break;
      case 5:
        sidebar = <Clientbar loggedUser={loggedUser} userId={user_params_id} />;
        break;
      default:
        sidebar = null;
    }
  }

  if (loggedUser) {
    switch (loggedUser.role_id) {
      case 1:
        sidebar = <Adminbar loggedUser={loggedUser} userId={user_params_id} />;
        break;
      case 2:
        sidebar = (
          <Distribbar loggedUser={loggedUser} userId={user_params_id} />
        );
        break;
      case 3:
        sidebar = <Resellbar loggedUser={loggedUser} userId={user_params_id} />;
        break;
      case 4:
        sidebar = <Techbar loggedUser={loggedUser} userId={user_params_id} />;
        break;
      case 5:
        sidebar = <Clientbar loggedUser={loggedUser} userId={user_params_id} />;
        break;
      default:
        sidebar = null;
    }
  }

  return sidebar;
}
