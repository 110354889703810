import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactTable from "react-table-v6";
import { useParams } from "react-router-dom";
import "../../../assets/css/custom.css";
import { Button, Card, Row, CardBody } from "reactstrap";
import { getApiConfig, API_URL, getUserRoleId } from "../Config/apiConfig";
import customFilterMethod from "helpers/customFilterMethod.js";
const localization = {
  previousText: "Précédent",
  nextText: "Suivant",
  pageText: "Page",
  ofText: "sur",
  rowsText: "lignes",
};
const roleId = getUserRoleId();
const shouldDisplayAlert = (alert, roleId) => {
  const alertsVisibility = {
    "Température interne trop élevé": [1, 2, 3, 4, 5],
    "Alerte humidité interne trop élévée": [1, 2, 3, 4, 5],
    "Capteur de vibration": [1, 2, 3, 4, 5],
    "Alerte le boitier a été ouvert par dessus": [1, 2, 3, 4, 5],
    "Alerte le boitier a été ouvert ou est exposé a une lumière trop forte": [1, 2, 3, 4],
    "Perte de connexion du boitier": [1, 2, 3, 4, 5],
    "Le boîtier a redémarré": [1, 2, 3, 4, 5],
    "Bobine débranché": [1, 2, 3, 4, 5],
  };

  return alertsVisibility[alert.title]?.includes(parseInt(roleId)) ?? true;
};

const getAlertColorClass = (title) => {
  const colorClassMap = {
    "Température interne trop élevé": "circle_form_orange",
    "Alerte humidité interne trop élévée": "circle_form_orange",
    "Capteur de vibration": "circle_form_orange",
    "Alerte le boitier a été ouvert par dessus": "circle_form_red",
    "Alerte le boitier a été ouvert ou est exposé a une lumière trop forte": "circle_form_red",
    "Perte de connexion du boitier": "circle_form_blue",
    "Le boîtier a redémarré": "circle_form_blue",
    "Bobine débranché": "circle_form_orange",
  };

  return colorClassMap[title] || "circle_form_red";
};

export default function TabAlert() {

  const params = useParams();

  // Download State
  const [loading, setLoading] = useState(false);

  // Data
  const [boitierAlert, setBoitierAlert] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}everyAlertByBoitier/${params.id}`, getApiConfig())
      .then((res) => {
        setBoitierAlert(res.data);
        console.log(res, "boitierAlert");
      })
      .catch((err) => {
        console.error(err);
      });
  }, [params.id]);

  const handleDownload = () => {
    axios
      .get(`${API_URL}exportAlertLog/${params.id}`, {
        ...getApiConfig(),
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const filteredAlerts = boitierAlert.filter((alert) => shouldDisplayAlert(alert, roleId));

  const columns = [
    {
      Header: () => <div style={{ textAlign: "center" }}>Couleur Alert</div>,
      accessor: (d) => (
        <div className={getAlertColorClass(d.title)} style={{ width: "20px", minWidth:"20px", minHeight:"20px", height: "20px", borderRadius: "50%" }} />
      ),
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>ALERTE</div>,
      accessor: (d) => {
        return d.unite === "Booléen" ? (
          <div>{d.title}</div>
        ) : (
          <div>
            {d.title}: {d.value} {d.unite}
          </div>
        );
      },
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>DATE</div>,
      accessor: (d) => d.datetime,
    },
  ].map((col, i) => {
    col.id = `${i}`;
    return col;
  });

  return (
    <>
      <Row className="CenterAll">
        <Card className="mt-5">
          <Card>
            <CardBody>
              {filteredAlerts.length > 0 ? (
                <ReactTable
                  defaultFilterMethod={customFilterMethod}
                  data={filteredAlerts}
                  columns={columns}
                  defaultPageSize={10}
                  previousText={localization.previousText}
                  nextText={localization.nextText}
                  pageText={localization.pageText}
                  ofText={localization.ofText}
                  rowsText={localization.rowsText}
                />
              ) : (
                <div>Aucune donnée disponible</div>
              )}
            </CardBody>
          </Card>
        </Card>
        <Button
          className="float-right"
          color="primary"
          disabled={loading}
          onClick={handleDownload}
        >
          {loading ? "Téléchargement..." : "Exporter Les Données"}
        </Button>
      </Row>
    </>
  );
}
