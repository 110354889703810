import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactTable from "react-table-v6";
import { CircleLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import "../../../assets/css/custom.css";
import { Button, Card, Row, Col, CardBody } from "reactstrap";
import { getApiConfig, API_URL } from "../Config/apiConfig";
import customFilterMethod from "helpers/customFilterMethod.js";

export default function TabLog() {
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [circleLoading, setCircleLoading] = useState(true);

  const [boitierLog, setBoitierLog] = useState([]);
  const [config, setConfig] = useState([]);
  const [primaryKey, setPrimaryKey] = useState([]);

  const localization = {
    previousText: "Précédent",
    nextText: "Suivant",
    pageText: "Page",
    ofText: "sur",
    rowsText: "lignes",
  };

  useEffect(() => {
    axios
      .get(`${API_URL}getConfig/${params.id}`, getApiConfig())
      .then((res) => {
        console.log(res, "de getconfig");
        setConfig(res.data);
        const primaryKeys = res.data
          .filter((c) => c.state === 1)
          .map((c) => c.id);
        setPrimaryKey(primaryKeys);
        console.log(primaryKeys, "primaryKeys");
      })
      .catch((err) => {
        console.error(err);
      });
  }, [params.id]);

  useEffect(() => {
    axios
      .get(`${API_URL}showBoitierLog/${params.id}`, getApiConfig())
      .then((res) => {
        setBoitierLog(res.data);
        setCircleLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setCircleLoading(false);
      });
  }, [params.id]);

  const handleDownload = () => {
    setLoading(true);
    axios
      .get(`${API_URL}showBoitierLog/${params.id}`, getApiConfig())
      .then((res) => {
        // Convertir les données en format CSV
        const csvData = convertToCSV(res.data);

        // Créer un blob avec les données CSV
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);

        // Créer un lien pour télécharger le fichier
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();

        // Nettoyer
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  // Fonction pour convertir les données en CSV
  const convertToCSV = (data) => {
    if (data.length === 0) return "";

    const headers = Object.keys(data[0]).join(",");
    const rows = data.map((obj) => Object.values(obj).join(","));

    return `${headers}\n${rows.join("\n")}`;
  };

  const columns = [
    {
      Header: () => <div style={{ textAlign: "center" }}>DATE</div>,
      accessor: "epoch_date",
    },
    ...primaryKey.map((id) => ({
      Header: () => (
        <div style={{ textAlign: "center" }}>
          {config.find((c) => c.id === id).label}
        </div>
      ),
      accessor: `capteur_${id}_data`,
    })),
  ];

  return (
    <>
      <Row className="CenterAll">
        <Col lg="12">
          <Card className="mt-5">
            <Col lg="12" className="d-flex justify-content-center">
              <Card>
                {circleLoading ? (
                  <div className="circle-placeholder">
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircleLoader color={"#3B90C8"} />
                    </div>
                  </div>
                ) : (
                  <CardBody>
                    {boitierLog.length > 0 ? (
                      <ReactTable
                        defaultFilterMethod={customFilterMethod}
                        data={boitierLog}
                        columns={columns}
                        defaultPageSize={10}
                        previousText={localization.previousText}
                        nextText={localization.nextText}
                        pageText={localization.pageText}
                        ofText={localization.ofText}
                        rowsText={localization.rowsText}
                      />
                    ) : (
                      <div>Aucune donnée disponible</div>
                    )}
                  </CardBody>
                )}
              </Card>
            </Col>
          </Card>
          <Row className="export-btn-parent">
            <Button
              className="export-btn"
              color="primary"
              disabled={loading}
              onClick={handleDownload}
            >
              {loading ? "Téléchargement..." : "Exporter Les Données"}
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  );
}
