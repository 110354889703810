import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import axios from "axios";
import { Alert } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { API_URL, getApiConfig, getUserRoleId, getUserId, getGoogleAPI } from "../Config/apiConfig.js";
import {OLD_API_URL, oldGetApiConfig} from "../Config/oldApiConfig.js"

export default function TechnicienAddForm({ role_id }) {
  const creatorUserId = getUserId()
  const nav = useNavigate();
  const apiKey =getGoogleAPI()
  const creatorRoleId = getUserRoleId()

  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const [markerLat, setMarkerLat] = useState("");
  const [markerLng, setMarkerLng] = useState("");
let oldPayload =  {
      contact : {
      nom: "",
      prenom: "",
      tel1: null,
      tel2: null,
      email: null,
      siteInternet: null,
      siret : 0,
      numTva : 0
    },
      adresse : {
        route : null,
        locality : null,
        postalCode : null,
        country : "",
        location : {
          lat: null,
          lng: null
        },
        viewport: {
        south: null,
        west:null,
        north: null,
        east: null,
      }},
      statut : 0,
      commentaire: "",
      revendeur : null 
 }
  const [newUser, setNewUser] = useState({
    name: null,
    firstname: null,
    email: null,
    phone1: null,
    phone2: null,
    markerLat: 43.64, // in waiting of order because i don't know if i have to set 43.64 or null
    markerLng: 5.1,
    address: null,
    postcode: null,
    city: null,
    country: null,
    state: null /*a modifier car pas de state dans le form*/,
    maintenance: 0,
    comment: null,
    role_id: 4,
    user_id: creatorUserId, //je crois qu'il ne faut pas mettre de userID car c'est le back qui s'occupe d'en mettre un au nv user (yan: oui c'est auto incrmenté)
  });
  const handleInputChange = (e) => {
    setNewUser((prevUser) => ({
      ...prevUser,
      [e.target.name]: e.target.value,
    }));
    console.log(newUser);
     
  };

  const handlePlaceSelected = (place) => {
    let address = place.address_components.find(
      (component) => component.types[0] === "route"
    ).long_name;
    if (
      place.address_components.find(
        (component) => component.types[0] === "street_number"
      )
    ) {
      address +=
        " " +
        place.address_components.find(
          (component) => component.types[0] === "street_number"
        ).long_name;
    }

    const postalCodeComponent = place.address_components.find(
      (component) => component.types[0] === "postal_code"
    );
    let postcode = "";
    if (postalCodeComponent) {
      postcode = postalCodeComponent.long_name;
    }

    const cityComponent = place.address_components.find(
      (component) => component.types[0] === "locality"
    );
    let city = "";
    if (cityComponent) {
      city = cityComponent.long_name;
    }

    const countryComponent = place.address_components.find(
      (component) => component.types[0] === "country"
    );
    let country = "";
    if (countryComponent) {
      country = countryComponent.long_name;
    }
    const adminArea1Component = place.address_components.find(
      (component) => component.types[0] === "administrative_area_level_1"
    );
    let adminArea1 = "";
    if (adminArea1Component) {
      adminArea1 = adminArea1Component.long_name;
    }
    const adminArea2Component = place.address_components.find(
      (component) => component.types[0] === "administrative_area_level_2"
    );
    let adminArea2 = "";
    if (adminArea2Component) {
      adminArea2 = adminArea2Component.long_name;
    }
const streetNumberComponent = place.address_components.find(
      (component) => component.types[0] === "street_number"
    );
    let streetNumber = "";
    if (streetNumberComponent) {
      streetNumber = streetNumberComponent.long_name;
    }
    

    oldPayload.adresse.administrativeAreaLevel1 = adminArea1;
    oldPayload.adresse.administrativeAreaLevel2 = adminArea2;
    oldPayload.adresse.streetNumber = streetNumber;
    setNewUser((prevUser) => ({
      ...prevUser,
      address,
      postcode,
      city,
      country,
    }));
    console.log(place, "googleplace")

  };

  const fullAddress = `${newUser.address}, ${newUser.city}, ${newUser.postcode}, ${newUser.country}`;

  useEffect(() => {
    const geocoder = new window.google.maps.Geocoder({
      apiKey: apiKey,
    });
    geocoder.geocode({ address: fullAddress }, (results, status) => {
      if (status === "OK") {
        const Ulocation = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
        setMarkerLat(Ulocation.lat);
        setMarkerLng(Ulocation.lng);
        setNewUser((prevUser) => ({
        ...prevUser,
        markerLat: Ulocation.lat,
        markerLng: Ulocation.lng,
      }));
      }
      
    });
    
    // eslint-disable-next-line
  }, [fullAddress]);

  const [errors, setErrors] = useState({});
  console.log(errors);

  const handleSubmit = async (e) => {
oldPayload =  {
      contact: {
      nom: newUser?.name,
      prenom: newUser?.firstname,
      tel1: newUser?.phone1,
      tel2: newUser?.phone2,
      email: newUser?.email
      },
      adresse : {
        route : newUser?.address,
        locality : newUser?.city,
        postalCode : newUser?.postcode,
        country : newUser?.country,
        location : {
          lat: markerLat,
          lng: markerLng
        },
        viewport: {
        south: parseFloat(markerLat) - 0.001,
        west: parseFloat(markerLng) - 0.001,
        north: parseFloat(markerLat) + 0.001,
        east: parseFloat(markerLng) + 0.001,
      }},
      statut : (newUser?.state == 1),
      commentaire: newUser?.comment,
      revendeur : null 
 }

    e.preventDefault();
    e.stopPropagation();
    e.persist();

    const newErrors = {};
    /* les traductions des erreurs sont ici, il faut ajouter une traduction si un champ et ajouté*/
    const translateName = {
      name: "Nom",
      firstname: "Prénom",
      email: "Email",
      phone1: "Téléphone",
      phone2: "Téléphone secondaire",
      website: "Site internet",
      siret: "SIRET",
      tva: "TVA",
      address: "Adresse",
      postcode: "Code postal",
      city: "Ville",
      country: "Pays",
      company: "Entreprise",
      maintenance: 0,
      comment: "Commentaire",
    };
    Object.keys(newUser).forEach((key) => {
      if (
        (role_id == 4 &&
          (key === "siret" || key === "website" || key === "company")) ||
        (role_id != 2 && role_id != 5 && key === "tva")
      ) {
        // Ignorer ces champs pour les rôles spécifiques
      } else if (newUser[key] === null) {
        if (
          key !== "phone2" &&
          key !== "website" &&
          key !== "tva" &&
          key !== "siret" &&
          key !== "company" &&
          key !== "comment"
        ) {
          newErrors[key] = `${translateName[key]} est requis. `;
        }
      } else {
        switch (key) {
          case "email":
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newUser[key])) {
              newErrors[key] = "Veuillez fournir une adresse e-mail valide.";
            }
            break;
          case "phone1":
            if (!/^\d+$/.test(newUser[key])) {
              newErrors[key] =
                "Veuillez fournir un numéro de téléphone valide.";
            }
            break;
          case "website":
            if (
              newUser[key] &&
              !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
                newUser[key]
              )
            ) {
              newErrors[key] = "Veuillez fournir une URL de site Web valide.";
            }
            break;
          case "phone2":
            if (newUser[key] && !/^\d+$/.test(newUser[key])) {
              newErrors[key] =
                "Veuillez fournir un numéro de téléphone valide.";
            }
            break;
          default:
            break;
        }
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    try {
      const response = await axios.post(
        API_URL + "register",
        newUser,
        getApiConfig()
      );
      console.log(response);

      if (response.status === 200) {
        setAlertContent(
          <Alert color="success">Les données ont bien été enregistrées !</Alert>
        );
        setAlert(true);
      } else {
        setAlertContent(
          <Alert color="danger">
            Une erreur est survenue, veuillez réessayer
          </Alert>
        );
        setAlert(true);
        const timeId = setTimeout(() => {
          setAlert(false);
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }

    try {
      console.log(oldPayload, "juste avant post des données, est ce que ça marche ajout des données")
      const response = await axios.post(
        OLD_API_URL + "technicien",
        oldPayload,
        oldGetApiConfig()
      );
      console.log(response);

      if (response.status === 201) {
        setAlertContent(
          <Alert color="success">Les données ont bien été enregistrées !</Alert>
        );
        setAlert(true);
        const timeId = setTimeout(() => {
          setAlert(false);   
            nav("/userList/4");
        }, 3000);
      } else {
        setAlertContent(
          <Alert color="danger">
            Une erreur est survenue, veuillez réessayer
          </Alert>
        );
        setAlert(true);
        const timeId = setTimeout(() => {
          setAlert(false);
        }, 3000);
      }
    } catch(error) {
      console.error(error)
    }
  };
  

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "10%",
          right: "10%",
          left: "20%",
          zIndex: "999",
        }}
      >
        {alert ? <div>{alertContent}</div> : <></>}
      </div>
      <Col lg="12" className="padLeft-1">
        <Row className="CenterAll ">
          <Col lg="12" className="mb-2 ">
            <div className="containertitle">
              <h2 className="titlePage">
                
                CREATION TECHNICIEN
                  
              </h2>
            </div>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <Row className="CenterAll" style={{ marginTop: "12px" }}>
              <Col lg="12">
                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <FormGroup className="col-md-6">
                      <Label for="name">Nom</Label>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        onChange={handleInputChange}
                        invalid={errors.name != undefined}
                      />
                      <FormFeedback>{errors.name}</FormFeedback>
                    </FormGroup>

                    <FormGroup className="col-md-6">
                      <Label for="firstname">Prénom</Label>
                      <Input
                        type="text"
                        name="firstname"
                        id="firstname"
                        onChange={handleInputChange}
                        invalid={errors.firstname !== undefined}
                      />
                      <FormFeedback>{errors.firstname}</FormFeedback>
                    </FormGroup>
                  </div>
                    
                <div className="form-row">
                
                        <FormGroup className="col-md-6">
                          <Label for="email">Email</Label>
                          <Input
                            type="email"
                            name="email"
                            id="email"
                            onChange={handleInputChange}
                            invalid={errors.email !== undefined}
                          />
                          <FormFeedback>{errors.email}</FormFeedback>
                        </FormGroup>
                        <FormGroup className="col-md-6">
                    <Label
                      for="inputState"
                      className="custom-label-form-creation"
                    >
                      Adresse
                    </Label>
                    <Autocomplete
                      apiKey={
                        `${apiKey}&libraries=places`
                      }
                      className={`form-control ${
                        errors.address !== undefined ? "is-invalid" : ""
                      }`}
                      onPlaceSelected={handlePlaceSelected}
                      options={{
                        types: ["address"],
                        componentRestrictions: { country: "fr" },
                      }}
                    />

                    <FormFeedback>{errors.address}</FormFeedback>
                  </FormGroup>
                </div>
                  
                  <div className="form-row">
                    <FormGroup className="col-md-6">
                      <Label for="phone1">Téléphone</Label>
                      <Input
                        type="tel"
                        name="phone1"
                        id="phone1"
                        onChange={handleInputChange}
                        invalid={errors.phone1 !== undefined}
                      />
                      <FormFeedback>{errors.phone1}</FormFeedback>
                    </FormGroup>

                    <FormGroup className="col-md-6">
                      <Label for="phone2">Téléphone secondaire</Label>
                      <Input
                        type="tel"
                        name="phone2"
                        id="phone2"
                        onChange={handleInputChange}
                        invalid={errors.phone2 !== undefined}
                      />
                      <FormFeedback>{errors.phone2}</FormFeedback>
                    </FormGroup>
                  </div>
                  
                  <div className="form-row">
                    {/* {creatorRoleId == 1 && (
                      <FormGroup className="col-md-6">
                        <Label
                          for="maintenance"
                          className="custom-label-form-creation"
                        >
                          Contrat de maintenance{" "}
                        </Label>
                        <Input
                          type="select"
                          name="maintenance"
                          id="maintenance"
                          onChange={handleInputChange}
                          invalid={errors.maintenance !== undefined}
                        >
                          <option>Choisir...</option>
                          <option value="1">Actif</option>
                          <option value="0">Inactif</option>
                        </Input>
                        <FormFeedback>{errors.maintenance}</FormFeedback>
                      </FormGroup>
                    )} */}
                    <FormGroup className="col-md-6">
                                    <Label for="state" className="custom-label-form-creation">Statut</Label>
                                    <Input type="select" name="state" id="state" onChange={handleInputChange} invalid={errors.state !== undefined} >
                                        <option>Choisir...</option>
                                        <option value="1">Actif</option>
                                        <option value="0">Inactif</option>
                                    </Input>
                                    <FormFeedback>{errors.status}</FormFeedback>
                                </FormGroup>
                  </div> 
                  <FormGroup className="col">
                    <Label for="comment" className="custom-label-form-creation">
                      Commentaire
                    </Label>
                    <Input
                      type="textarea"
                      name="comment"
                      id="comment"
                      onChange={handleInputChange}
                      invalid={errors.comment !== undefined}
                    />
                    <FormFeedback>{errors.comment}</FormFeedback>
                  </FormGroup>
                  <Button type="submit">Envoyer</Button>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}
