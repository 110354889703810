// import { oldToken } from "../../../layouts/Auth/Auth";

const OLD_API_URL = "https://api-acquagest.shenron.dev/api/";

 const oldToken = localStorage.getItem("oldtoken");

function oldGetApiConfig() {
  console.log("oldToken: " + oldToken);
  
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: oldToken ? `Bearer ${oldToken}` : undefined,
    },
  };
}

function oldGetApiConfigLogin() {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
}

function getOldMultipartConfig() {
  const oldTokenFromStorage = localStorage.getItem("oldtoken");
  console.log("oldToken_multipart: " + oldTokenFromStorage);

  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: oldTokenFromStorage ? `Bearer ${oldTokenFromStorage}` : undefined,
    },
  };
}

function oldGetUserRoleId() {
  return localStorage.getItem("user_role_id");
}

function oldGetUserId() {
  return localStorage.getItem("user_params_id");
}

export {
  oldToken, // Exportation d'oldToken
  OLD_API_URL,
  oldGetApiConfig,
  getOldMultipartConfig,
  oldGetUserRoleId,
  oldGetUserId,
  oldGetApiConfigLogin,
};