import React, { useState } from 'react';
import DeletePng from "./../../../assets/png/multiply.png";
import { Link } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const DocumentGallery = ({ documents, onDeleteDocument, isOld }) => {
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [modal, setModal] = useState(false);

  // Fonction pour ouvrir/fermer le modal
  const toggleModal = () => setModal(!modal);

  // Fonction pour détecter le type de fichier
  const getFileType = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) return 'image';
    if (extension === 'pdf') return 'pdf';
    return 'other';
  };

  // Fonction pour gérer le clic sur un document
  const handleDocumentClick = (url) => {
      setSelectedPdf(url); // Stocke l'URL du PDF
      toggleModal(); // Ouvre le modal
    
  };

  // Fonction pour afficher les documents
  const renderDocument = (url, index) => {
    const fileType = getFileType(url);
    
    return (
      <div key={index} className="custom-gallery-item">
        <div onClick={() => handleDocumentClick(url)}>
          {fileType === 'image' && (
            <img src={url} alt={`gallery-item-${index}`} />
          )}
          {fileType === 'pdf' && (
            <div className="pdf-preview">
              <Button color="primary" onClick={() => handleDocumentClick(url)}>
                PDF: {url.split('/').pop()}
              </Button>
            </div>
          )}
          {fileType === 'other' && (
            <div className="other-file">
              <span>File: {url.split('/').pop()}</span>
            </div>
          )}
          {isOld == false && (
            <button
              className="delete-image-btn"
              onClick={(e) => {
                e.stopPropagation();
                onDeleteDocument(url);
              }}
            >
              <img src={DeletePng} alt="Delete" />
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
        {documents.map((url, index) => renderDocument(url, index))}

      {/* Modal pour afficher le PDF */}
      {selectedPdf && (
        <Modal isOpen={modal} toggle={toggleModal} size="lg">
          <ModalHeader toggle={toggleModal}>Element Viewer</ModalHeader>
          <ModalBody>
            {/* Utilisation de iframe pour afficher le PDF dans le Modal */}
           {selectedPdf.endsWith(".pdf") ? (
            // Affichage PDF
            <iframe
              src={`${selectedPdf}#toolbar=0`}
              width="100%"
              height="600px"
              title="PDF Viewer"
            />
          ) : (
            // Affichage de l'image centrée
            <img
              src={selectedPdf}
              alt="Selected"
              style={{ maxWidth: "100%", maxHeight: "600px", display: "block" }}
            />
          )}
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default DocumentGallery;
