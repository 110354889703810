import react, { useState, useEffect } from "react";
import "../../../../assets/css/custom.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Card,
  Row,
  CardBody,
  Col,
  CardFooter,
  CardHeader,
  Button,
  Input,
  // Row,
} from "reactstrap";
import {
  OLD_API_URL,
  oldGetApiConfig,
  getOldMultipartConfig,
  oldGetUserRoleId,
  oldGetUserId,
} from "views/composant/Config/oldApiConfig";
import axios from "axios";
import ReactTablesUsed from "views/tables/ReactTablesUsed";
import "../../../../assets/css/table.css";
import customFilterMethod from "helpers/customFilterMethod.js";

export default function Reglages() {
  const [data, setData] = useState();
  const [email, setEmail] = useState(""); 
  const nav = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      nav("/login");
    }
  }, [nav]);

  useEffect(() => {
    axios
      .get(OLD_API_URL + `capteur`, oldGetApiConfig())
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error, "error in getting capteur data");
      });
  }, []);

  const handleSave = () => {
    const token = localStorage.getItem("token"); // Récupérer le token d'authentification

    axios
      .post(
        `https://acquaback.shenron.dev/api/updateEmail`, // Modifier l'ID de l'utilisateur si nécessaire
        {
          email: email, // L'email à mettre à jour
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "User-Agent": "insomnia/9.3.3",
          },
        }
      )
      .then((response) => {
        console.log("Email mis à jour avec succès :", response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la mise à jour de l'email :", error);
      });
  };

  return (
    <>
      <div className="main-box">
        {/* <Sidebar /> */}
        <div className="content-wrapper-custom">
          <Col>
            <Row className="CenterAll">
              <Card>
                
                <CardBody>
                  <CardHeader>
                    <h4>E-mail du compte administrateur</h4>
 <Row>
                      <Input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} // Capture la modification de l'input
                        placeholder="Saisir un email"
                      />
                      <Button color="primary" onClick={handleSave}>
                        Save
                      </Button>
                    </Row>
                  </CardHeader>
                </CardBody>
              </Card>
            </Row>
            <ReactTablesUsed data={data} />
            
            <Row className="CenterAll">
              <div className="containertitle">
                <h2 className="titlePage">VERSIONS</h2>
              </div>
            </Row>
            
             <Row className="CenterAll">
              <Card>
                <CardBody>
                  <table class="custom-table-style">
                    <thead>
                      <tr class="version-settings-header-custom">
                        <th>
                          <h4>Nom de la version</h4>
                        </th>
                        <th>
                          <h4>Description</h4>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Version 3.1</td>
                        <td>
                          <ul className="custom-reglages-dot">
                            Générateur A :<li>Courant : 250mA</li>
                            <li>Fréquences : 625Hz à 2.5Khz</li>
                          </ul>
                          <ul className="custom-reglages-dot">
                            Générateur B :<li>Courant : 1A</li>
                            <li>Fréquences : 2.5KHz à 10Khz</li>
                          </ul>
                          <ul className="custom-reglages-dot">
                            Boost :<li>Allumé : 6 minutes</li>
                            <li>Éteint : 12 minutes</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Version 3.2</td>
                        <td>
                          <ul className="custom-reglages-dot">
                            Générateur A :<li>Courant : 500mA</li>
                            <li>Fréquences : 625Hz à 2.5Khz</li>
                          </ul>
                          <ul className="custom-reglages-dot">
                            Générateur B :<li>Courant : 1A</li>
                            <li>Fréquences : 2.5KHz à 10Khz</li>
                          </ul>
                          <ul className="custom-reglages-dot">
                            Boost :<li>Allumé : 6 minutes</li>
                            <li>Éteint : 12 minutes</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Version 3.3</td>
                        <td>
                          <ul className="custom-reglages-dot">
                            Générateur A :<li>Courant : 1A</li>
                            <li>Fréquences : 625Hz à 2.5Khz</li>
                          </ul>
                          <ul className="custom-reglages-dot">
                            Générateur B :<li>Courant : 1A</li>
                            <li>Fréquences : 2.5KHz à 10Khz</li>
                          </ul>
                          <ul className="custom-reglages-dot">
                            Pas de boost possible
                          </ul>
                        </td>
                      </tr>
                       <tr>
                    <td>Version 3.4</td>
                    <td>
                      <ul className="custom-reglages-dot">Générateur A :
                        <li>Courant : 2A</li>
                        <li>Fréquences : 625Hz à 2.5Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">Générateur B :
                        <li>Courant : 1A</li>
                        <li>Fréquences : 2.5KHz à 10Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">Boost :
                        <li>Allumé : 6 minutes</li>
                        <li>Éteint : 12 minutes</li>
                      </ul>
                    </td>
                  </tr> 
             <tr>
                    <td>Version 3.5</td>
                    <td>
                      <ul className="custom-reglages-dot">Générateur A :
                        <li>Courant : 4A</li>
                        <li>Fréquences : 625Hz à 2.5Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">Générateur B :
                        <li>Courant : 1A</li>
                        <li>Fréquences : 2.5KHz à 10Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">Pas de boost possible
                      </ul>
                    </td>
                  </tr> 
                      <tr>
                        <td>Version 4.1</td>
                        <td>
                          <ul className="custom-reglages-dot">
                            Générateur A :<li>Courant : 500mA</li>
                            <li>Fréquences : 625Hz à 2.5Khz</li>
                          </ul>
                          <ul className="custom-reglages-dot">
                            Générateur B :<li>Courant : 1A</li>
                            <li>Fréquences : 2.5KHz à 10Khz</li>
                          </ul>
                          <ul className="custom-reglages-dot">
                            Boost :<li>Allumé : 6 minutes</li>
                            <li>Éteint : 12 minutes</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Version 4.2</td>
                        <td>
                          <ul className="custom-reglages-dot">
                            Générateur A :<li>Courant : 1A</li>
                            <li>Fréquences : 625Hz à 2.5Khz</li>
                          </ul>
                          <ul className="custom-reglages-dot">
                            Générateur B :<li>Courant : 1A</li>
                            <li>Fréquences : 2.5KHz à 10Khz</li>
                          </ul>
                          <ul className="custom-reglages-dot">
                            Boost :<li>Allumé : 6 minutes</li>
                            <li>Éteint : 12 minutes</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Version 4.3</td>
                        <td>
                          <ul className="custom-reglages-dot">
                            Générateur A :<li>Courant : 2A</li>
                            <li>Fréquences : 625Hz à 2.5Khz</li>
                          </ul>
                          <ul className="custom-reglages-dot">
                            Générateur B :<li>Courant : 1A</li>
                            <li>Fréquences : 2.5KHz à 10Khz</li>
                          </ul>
                          <ul className="custom-reglages-dot">
                            Boost :<li>Allumé : 6 minutes</li>
                            <li>Éteint : 12 minutes</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Version 4.4</td>
                        <td>
                          <ul className="custom-reglages-dot">
                            Générateur A :<li>Courant : 4A</li>
                            <li>Fréquences : 625Hz à 2.5Khz</li>
                          </ul>
                          <ul className="custom-reglages-dot">
                            Générateur B :<li>Courant : 1A</li>
                            <li>Fréquences : 2.5KHz à 10Khz</li>
                          </ul>
                          <ul className="custom-reglages-dot">
                            Boost :<li>Allumé : 6 minutes</li>
                            <li>Éteint : 12 minutes</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Version 4.5</td>
                        <td>
                          <ul className="custom-reglages-dot">
                            Générateur A :<li>Courant : 8A</li>
                            <li>Fréquences : 625Hz à 2.5Khz</li>
                          </ul>
                          <ul className="custom-reglages-dot">
                            Générateur B :<li>Courant : 1A</li>
                            <li>Fréquences : 2.5KHz à 10Khz</li>
                          </ul>
                          <ul className="custom-reglages-dot">
                            Pas de boost possible
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Row>
          </Col>
        </div>
      </div>
    </>
  );
}
