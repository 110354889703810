import React, { useState, useEffect } from "react";
import "react-table-v6/react-table.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";

import ReactTable from "react-table-v6";
import {
  API_URL,
  getApiConfig,
  getUserRoleId,
  token,
} from "../../Config/apiConfig.js";
import {
  OLD_API_URL,
  oldGetApiConfig,
  oldToken,
} from "../../Config/oldApiConfig.js";
import customFilterMethod from "helpers/customFilterMethod.js";
import statusFilterMethod from "helpers/statusFilterMethod.js";

export default function AdminTabInfo() {
  const nav = useNavigate();
  const [boitierList, setBoitierList] = useState([]);
  const userRoleId = getUserRoleId();
  const localization = {
    previousText: "Précédent",
    nextText: "Suivant",
    pageText: "Page",
    ofText: "sur",
    rowsText: "lignes",
  };

  const onRowClick = (rowInfo) => {
    const isOld = rowInfo.noSerie >= 0 && rowInfo.noSerie <= 3500;
    nav(`/showBoitier/${rowInfo.id}`, { state: { isOld, id: rowInfo.id } });
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    if (oldToken) {
      axios
        .get(OLD_API_URL + "boitieradmin", oldGetApiConfig())
        .then((res) => {
          const ancienneData = res.data.data;
          ancienneData.forEach((boitier) => {
            boitier.statut =
              boitier.statut === true || boitier.statut === "actif"
                ? "actif"
                : "inactif";
            boitier.actif =
              boitier.actif === true || boitier.actif === "actif"
                ? "actif"
                : "inactif";
            boitier.diagnostic =
              boitier.diagnostic || boitier.diagnostic === "actif"
                ? "actif"
                : "inactif";
          });
          console.log("Ancienne API:", ancienneData);
          if (Array.isArray(ancienneData)) {
            setBoitierList((prevList) => [...prevList, ...ancienneData]);
          } else {
            console.error(
              "Les données de l'ancienne API ne sont pas un tableau :",
              ancienneData
            );
          }
        })
        .catch((err) => {
          console.error(
            "Une erreur s'est produite lors de la récupération des données de l'API ancienne:",
            err
          );
        });
    }
  }, []);

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Numéro de série",
      accessor: "noSerie",
    },
    {
      Header: "Date d'activation",
      accessor: "dateActivation",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: "Version installée",
      accessor: "versionInstalle",
    },
    {
      Header: "Dernière actualisation",
      accessor: "derniereActualisation",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: "Statut",
      accessor: "statut",
      Cell: ({ value }) => {
        return (
          <div
            style={{
              color: value === "actif" ? "green" : "red",
              textAlign: "center",
            }}
          >
            {value}
          </div>
        );
      },
      filterMethod: statusFilterMethod,
    },
    {
      Header: "Firmware Version",
      accessor: "firmwareVersion",
    },
    {
      Header: "Première connexion",
      accessor: "firstConnectDate",
      Cell: ({ value }) => (value ? formatDate(value) : "N/A"),
    },
    {
      Header: "Mode de communication",
      accessor: "communicationMode",
    },
    {
      Header: "Client",
      accessor: "client",
    },
    {
      Header: "Revendeur",
      accessor: "revendeur",
    },
    {
      Header: "Concessionnaire",
      accessor: "concessionnaire",
    },
    {
      Header: "PIN SIM",
      accessor: "pin_sim",
    },
    {
      Header: "ICCID SIM",
      accessor: "iccid_sim",
    },
    {
      Header: "Lot SIM",
      accessor: "lot_sim",
    },
    {
      Header: "Diagnostic",
      accessor: "diagnostic",
      Cell: ({ value }) => {
        return (
          <div
            style={{
              color: value === "actif" ? "green" : "red",
              textAlign: "center",
            }}
          >
            {value}
          </div>
        );
      },
      filterMethod: statusFilterMethod,
    },
    {
      Header: "Actif",
      accessor: "actif",
      Cell: ({ value }) => {
        return (
          <div
            style={{
              color: value === "actif" ? "green" : "red",
              textAlign: "center",
            }}
          >
            {value}
          </div>
        );
      },
      filterMethod: statusFilterMethod,
    },
  ];

  return (
    <>
      <Row className="CenterAll">
        <Col lg="12">
          <Card className="mt-5">
            <div>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {" "}
                    <ReactTable
                      filterable
                      defaultFilterMethod={customFilterMethod}
                      data={boitierList}
                      columns={columns}
                      defaultPageSize={10}
                      previousText={localization.previousText}
                      nextText={localization.nextText}
                      pageText={localization.pageText}
                      ofText={localization.ofText}
                      rowsText={localization.rowsText}
                      getTrProps={(state, rowInfo) => ({
                        onClick: () => onRowClick(rowInfo.original),
                      })}
                    />
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}
