import react, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "react-table-v6/react-table.css";
import ReactTable from "react-table-v6";
import "../../../../assets/css/custom.css";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { getApiConfig, API_URL } from "views/composant/Config/apiConfig";
import customFilterMethod from "helpers/customFilterMethod";

export default function ShowDistributeurTab() {
  const nav = useNavigate();

  const params = useParams();

  //DATA
  const [resellerList, setResellerList] = useState("");

  const localization = {
    previousText: "Précédent",
    nextText: "Suivant",
    pageText: "Page",
    ofText: "sur",
    rowsText: "lignes",
  };

  useEffect(() => {
    axios
      .get(API_URL + `showChildListBelongToParent/${params.id}`, getApiConfig())

      .then((res) => {
        setResellerList(res);
      })
      .catch((err) => {});
  }, [params.id]);

  const columns = [
    {
      Header: () => <div style={{ textAlign: "center" }}>Entreprise</div>,
      accessor: (d) => d.company,
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>CONTACT</div>,
      accessor: (d) => `${d.name}  ${d.firstname}`,
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>PAYS</div>,
      accessor: (d) => d.country,
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>STATUS</div>,
      accessor: (d) => d.state,
      Cell: ({ value }) => (
        <div
          style={{ color: value === 1 ? "green" : "red", textAlign: "center" }}
        >
          {value === 1 ? "actif" : "inactif"}
        </div>
      ),
    },
  ].map((col, i) => {
    col.id = `${i}`;
    return col;
  });

  const onRowClick = (state, rowInfo, column, instance) => {
    return {
      onClick: (e) => {
        nav(`/showResel/${rowInfo.original.id}`);
      },
    };
  };

  return (
    <>
      <Row className="CenterAll">
        <div className="containertitle">
          <h2 className="titlePage">LISTE REVENDEUR</h2>
        </div>
      </Row>
      <Row className="CenterAll">
        <Col lg="12">
          <Card className="mt-5">
            <Col lg="12" className="d-flex justify-content-center">
              <Card>
                <CardBody>
                  {resellerList ? (
                    <ReactTable
                      filterable
                      defaultFilterMethod={customFilterMethod}
                      getTrProps={onRowClick}
                      value={resellerList?.data.id}
                      data={resellerList?.data}
                      columns={columns}
                      defaultPageSize={10}
                      previousText={localization.previousText}
                      nextText={localization.nextText}
                      pageText={localization.pageText}
                      ofText={localization.ofText}
                      rowsText={localization.rowsText}
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  );
}
