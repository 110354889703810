import React from "react";
import { useLocation, useParams } from "react-router-dom";
import "../../../../assets/css/custom.css";
import { getUserRoleId } from "../../Config/apiConfig.js";
// import { OLD_API_URL, oldGetApiConfig } from "../Config/oldApiConfig";
// import { API_URL, getApiConfig } from "../Config/apiConfig.js";
import axios from "axios";
import { Card, Row, Input, Col } from "reactstrap";
import AdminTabInfo from "./adminTabInfo.js";
export default function AdminInfo() {
  return (
    <>
      <div className="main-box">
        <div className="content-wrapper-custom">
          <Row className="CenterAll">
            <Col lg="12">
              <div className="containertitle">
                <h2 className="titlePage">Informations Boitiers </h2>
              </div>
            </Col>
          </Row>
          <AdminTabInfo />
        </div>
      </div>
    </>
  );
}
