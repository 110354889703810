const statusFilterMethod = (filter, row) => {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id])
        .toLowerCase()
        .startsWith(String(filter.value).toLowerCase())
    : true;
};

export default statusFilterMethod;
