import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Button, Row, Col, Card, CardBody, CardFooter } from "reactstrap";
import Clients_3 from "../../../assets/svg/Clients_3.svg";
import Clients from "../../../assets/svg/Clients.svg";
import { API_URL, getApiConfig, token } from "../Config/apiConfig.js";
import axios from "axios";
import { OLD_API_URL, oldGetApiConfig, oldToken } from "../Config/oldApiConfig";

export default function UserListHeader({ role_id }) {
  const [userCountRequest, setUserCountRequest] = useState("");
  const [oldUserCountRequest, setOldUserCountRequest] = useState("");
  useEffect(() => {
    setUserDataCount(null);

    if (role_id == 2) {
      setUserCountRequest("countTotalConcess");
    } else if (role_id == 3) {
      setUserCountRequest("countTotalReseller");
    } else if (role_id == 4) {
      setUserCountRequest("countTotalTech");
    } else {
      setUserCountRequest("countTotalCustomer");
    }
  }, [role_id]);

  useEffect(() => {
    if (role_id == 2) {
      setOldUserCountRequest("concessionnaire");
    } else if (role_id == 3) {
      setOldUserCountRequest("revendeur");
    } else if (role_id == 4) {
      setOldUserCountRequest("technicien");
    } else {
      setOldUserCountRequest("client");
    }
  }, [role_id]);

  const [userDataCount, setUserDataCount] = useState(null);
  const [oldUserDataCount, setOldUserDataCount] = useState(null);
  const [allData, setAllData] = useState(null);

  useEffect(() => {
    if (token) {
      if (userCountRequest && !userDataCount) {
        axios
          .get(API_URL + userCountRequest, getApiConfig())
          .then((res) => {
            setUserDataCount(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    if (oldToken) {
      if (oldUserCountRequest && !oldUserDataCount) {
        axios
          .get(OLD_API_URL + oldUserCountRequest, oldGetApiConfig())
          .then((res) => {
            setOldUserDataCount(res);
          })
          .catch((err) => {
            console.error(
              "Erreur lors de la récupération des données de l'ancienne API :",
              err
            );
          });
      }
    }
  }, [userCountRequest, oldUserDataCount, oldUserCountRequest, userDataCount]);

  return (
    <>
      <Row className="CenterAll">
        <Col lg="12">
          <div className="containertitle">
            <h2 className="titlePage titlePage-UserList">
              {role_id == 2
                ? "DISTRIBUTEURS"
                : role_id == 3
                ? "REVENDEURS"
                : role_id == 4
                ? "TECHNICIENS"
                : role_id == 5
                ? "CLIENTS"
                : "DEFAULT TITLE"}
            </h2>
            <Button className="custom-btn-add mt-4 add-Bottom" color="primary">
              <NavLink
                to={`/UserAdd/${role_id}`}
                className="nav-link text-primary"
              >
                <p>
                  {role_id == 2
                    ? "AJOUTER UN DISTRIBUTEUR"
                    : role_id == 3
                    ? "AJOUTER UN REVENDEUR"
                    : role_id == 4
                    ? "AJOUTER UN TECHNICIEN"
                    : role_id == 5
                    ? "AJOUTER UN CLIENT"
                    : "DEFAULT TITLE"}
                </p>
                <i className="tim-icons icon-simple-add text-white" />
              </NavLink>
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="CenterAll">
        <Col lg="12">
          <Card className="card-stats">
            <h3 className="titlePage2"> Informations générales</h3>
            <Row>
              <Col lg="4" md="6">
                <CardBody>
                  <Col className="headerCenter">
                    <img
                      src={Clients_3}
                      className="userImage"
                      alt="React Logo"
                    />
                  </Col>
                  <Col className="headerCenter">
                    <h3>
                      {role_id == 2
                        ? "Nombre de Distributeurs"
                        : role_id == 3
                        ? "Nombre de Revendeur"
                        : role_id == 4
                        ? "Nombre de Techniciens"
                        : role_id == 5
                        ? "Nombre de Clients"
                        : "DEFAULT TITLE"}
                    </h3>
                  </Col>
                  <Col className="headerCenter">
                    {/* <h3>{userDaCount && userDataCount.data.total } {oldUserDataCount && oldUserDataCount.data.meta.nb_total} </h3> */}
                    {
                      <h3>
                        {userDataCount?.data.total &&
                        oldUserDataCount?.data.meta.nb_total
                          ? userDataCount?.data.total +
                            oldUserDataCount?.data.meta.nb_total
                          : userDataCount?.data.total
                          ? userDataCount?.data.total
                          : oldUserDataCount?.data.meta.nb_total
                          ? oldUserDataCount?.data.meta.nb_total
                          : null}
                      </h3>
                    }

                    {/*  + oldUserDataCount.data.meta.nb_total : null} */}
                  </Col>
                </CardBody>
                <CardFooter></CardFooter>
              </Col>

              <Col lg="4" md="6">
                <CardBody>
                  <Col className="headerCenter">
                    <img src={Clients} className="userImage" alt="React Logo" />
                  </Col>
                  <Col className="headerCenter">
                    <h3>
                      {role_id == 2
                        ? "Distributeurs actifs"
                        : role_id == 3
                        ? "Revendeur actifs"
                        : role_id == 4
                        ? "Techniciens actifs"
                        : role_id == 5
                        ? "Clients actifs"
                        : "DEFAULT TITLE"}
                    </h3>
                  </Col>
                  <Col className="headerCenter">
                    {
                      <h3>
                        {userDataCount?.data.active &&
                        oldUserDataCount?.data.meta.nb_actif
                          ? userDataCount?.data.active +
                            oldUserDataCount?.data.meta.nb_actif
                          : userDataCount?.data.active
                          ? userDataCount?.data.active
                          : oldUserDataCount?.data.meta.nb_actif
                          ? oldUserDataCount?.data.meta.nb_actif
                          : null}
                      </h3>
                    }
                  </Col>
                </CardBody>
                <CardFooter></CardFooter>
              </Col>

              <Col lg="4" md="6">
                <CardBody>
                  <Col className="headerCenter">
                    <img
                      src={Clients_3}
                      className="userImage"
                      alt="React Logo"
                    />
                  </Col>
                  <Col className="headerCenter">
                    <h3>
                      {role_id == 2
                        ? "Distributeurs inactifs"
                        : role_id == 3
                        ? "Revendeur inactifs"
                        : role_id == 4
                        ? "Techniciens inactifs"
                        : role_id == 5
                        ? "Clients inactifs"
                        : "DEFAULT TITLE"}
                    </h3>
                  </Col>
                  <Col className="headerCenter">
                    {
                      <h3>
                        {userDataCount?.data.inactive &&
                        oldUserDataCount?.data.meta.nb_inactif
                          ? userDataCount?.data.inactive +
                            oldUserDataCount?.data.meta.nb_inactif
                          : userDataCount?.data.inactive
                          ? userDataCount?.data.inactive
                          : oldUserDataCount?.data.meta.nb_inactif
                          ? oldUserDataCount?.data.meta.nb_inactif
                          : 0}
                      </h3>
                    }
                  </Col>
                </CardBody>
                <CardFooter></CardFooter>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}
