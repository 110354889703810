import React from 'react';
import {CardBody,Row,Button,Modal, ModalHeader, ModalBody,Input} from "reactstrap";
import '../../../assets/css/custom.css';
import { getUserRoleId } from '../Config/apiConfig.js';

export default function ShowBoitierComment({comment, setComment, handleSubmitFor}) {

    const [modalComment, setModalComment] = React.useState(false);
    const toggleModalComment = () => {
        setModalComment(!modalComment)
    }

    const userRoleId = getUserRoleId();

    return (
    <>
    <Modal isOpen={modalComment} toggle={toggleModalComment} size="lg" >
        <ModalHeader className="justify-content-center" toggle={toggleModalComment}>
            <h4>
                Nommez un boîtier
            </h4>
        </ModalHeader>
        <ModalBody>
            <form onSubmit={handleSubmitFor}>
                <Input value={comment} className="heighTextArea pl-3"  type="textarea"  cols="50" rows="15" name="text" id="exampleText" placeholder="Informations complémentaires..." onChange={e => setComment(e.target.value)}/>
                <Button className="float-right mt-3 mr-5" type="submit"   color="primary" onClick={toggleModalComment}>
                    Ajouter
                </Button>
            </form>
        </ModalBody>
    </Modal>
    <CardBody className="rightContainer">
        <Row className="d-flex justify-content-between mr-4 ml-3">
            <h4 className="titleCardContrast">
                Nom du boîtier
            </h4>
            <div style={{ borderTop: "0.5px solid #fff" , marginBottom:'20px'}}></div>
            {userRoleId != 5 && (
              <Button className="btn-round btn-icon "   color="primary" onClick={toggleModalComment}>
                  <i className="tim-icons icon-simple-add text-white"  />
              </Button>
            )}
        </Row>
        <div className="mt-4 pb-3 mr-4 ml-4">{comment}</div>
    </CardBody>
    </>
  );
};
