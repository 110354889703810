import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  CardBody,
  Row,
  Input,
} from "reactstrap";
import "../../../assets/css/custom.css";
import { useParams } from "react-router-dom";
import {
  OLD_API_URL,
  oldGetApiConfig,
  getOldMultipartConfig,
} from "../Config/oldApiConfig";
import DocumentGallery from "./showDocImg";
// import DeletePng from "./../../../assets/png/multiply.png";

function ShowOldBoitierImage() {
  const old = true;
  const params = useParams();

  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const [selectedImages, setSelectedImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]); // État pour les URLs des images

  const [modalGallery, setModalGallery] = useState(false);
  const toggleModalGallery = () => {
    setModalGallery(!modalGallery);
  };

  const [rapport, setRapport] = useState({
    id: null,
    time: "",
    dateActivation: "",
    commentaire: "",
    created_at: "",
    boitierId: null,
    technicien: null,
    photo: [],
  });

  const fetchImageUrls = () => {
    axios
      .get(OLD_API_URL + `boitier/${params.id}`, oldGetApiConfig())
      .then((res) => {
        const fetchedRapport = res.data.data.rapport;
        fetchedRapport.commentaire = res.data.data.rapport.commentaire ?? "";
        setRapport(fetchedRapport);
        setImageUrls(fetchedRapport.photo.map((photo) => photo.name)); // Mettre à jour les URLs des images
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchImageUrls();
  }, [params.id]);

  const handleAddImages = async (e) => {
    e.preventDefault();
    const date = new Date();
    const formattedDate = date
      .toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "-"); // dd-mm-yyyy on formate bien la date en remplacant le / par -
    const formattedTime = date.toTimeString().split(" ")[0].slice(0, 5); // HH:mm

    try {
      const formData = new FormData();
      formData.append("technicien", 4);
      selectedImages.forEach((image, index) => {
        formData.append(`photo[${index}]`, image);
      });
      formData.append("dateActivation", formattedDate);
      formData.append("time", formattedTime);
      formData.append("boitierId", params.id);
      formData.append("commentaire", rapport.commentaire); // peut etre faut il mettre que photo dans la boucle forEach

      const response = await axios.post(
        OLD_API_URL + `rapport`,
        formData,
        getOldMultipartConfig()
      );

      // Mettez à jour l'état avec les nouvelles images
      setSelectedImages([]);
      fetchImageUrls(); // Réactiver le fetch des images
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedImages(files);
  };
  const getFileType = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) return 'image';
    if (extension === 'pdf') return 'pdf';
    return 'other';
  };

  const handleDeleteImage = (url) => {
    // Implémentez la logique de suppression d'image ici, s'il y en a une
  };
const showSelectedImages = (url, index) => {
    const fileType = getFileType(url.name);

    if (fileType === "pdf") {
        return (
            <div key={index} className="custom-gallery-item">
                <div className="image-container">
                    PDF: {url.name.split('/').pop()}
                </div>
            </div>
        );
    }

    if (fileType === "image") {
        return (
            <div key={index} className="custom-gallery-item">
                <div className="image-container">
                    <img
                        src={URL.createObjectURL(url)}
                        alt="selected"
                        style={{
                            maxHeight: "300px",
                            maxWidth: "25vw",
                            minWidth: "90px",
                        }}
                    />
                </div>
            </div>
        );
    }

    return null; // Au cas où ce ne serait ni une image, ni un PDF
};

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "10%",
          right: "10%",
          left: "20%",
          zIndex: "999",
        }}
      >
        {alert ? <div>{alertContent}</div> : <></>}
      </div>
      <CardBody className="mt-4 rightContainer">
        <div className="text-center">
          <Button type="submit" color="primary" onClick={toggleModalGallery}>
            Voir les photos d'installations
          </Button>
        </div>
      </CardBody>

      <Modal
        isOpen={modalGallery}
        toggle={toggleModalGallery}
        className="custom-modal-gallery "
        size="lg"
      >
        <ModalHeader
          className="justify-content-center"
          toggle={toggleModalGallery}
        >
          Photo installation
        </ModalHeader>
        <ModalBody>
          <CardBody className="rightContainer">
            <Row className="d-flex justify-content-between mr-4 ml-3">
              <h4 className="titleCardContrast">Ajouter des Images:</h4>
            </Row>
            <div className="text-center">
              <form onSubmit={handleAddImages}>
                <Row>
                  <Input
                    color="primary"
                    type="file"
                    multiple // Permet la sélection multiple
                    onChange={handleImageChange}
                  />
                  <Button color="primary" type="submit">
                    Valider
                  </Button>
                </Row>
              </form>
            </div>
          </CardBody>

          {selectedImages.length > 0 && (
          
            <CardBody className="mt-4 rightContainer">
              <ModalHeader>
              Images sélectionnées :</ModalHeader>
              <div className="custom-gallery scrollable-gallery">
                {selectedImages.map((image, index) => (
                  showSelectedImages(image, index)
                ))}
              </div>
            </CardBody>
          )}

          <CardBody className="mt-4 rightContainer">
            <div className="custom-gallery scrollable-gallery">
              <DocumentGallery documents={imageUrls} isOld={old} ></DocumentGallery>
              {/*<button
                      className="delete-image-btn"
                      onClick={() => handleDeleteImage(url)}
                    >
                      <img src={DeletePng} alt="Delete" />
                    </button>
                    */}
            </div>
          </CardBody>
        </ModalBody>
      </Modal>
    </>
  );
}
export default React.memo(ShowOldBoitierImage);
