import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  CardBody,
  Row,
  Input,
} from "reactstrap";
import "../../../assets/css/custom.css";
import { useParams } from "react-router-dom";
import {
  API_URL,
  getApiConfig,
  getMultipartConfig,
} from "../Config/apiConfig.js";
import DeletePng from "./../../../assets/png/multiply.png";
import DocumentGallery from "./showDocImg.js"
export default function ShowBoitierImage() {
  const params = useParams();

  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  // Tableau pour plusieurs images
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);

  const [modalGallery, setModalGallery] = useState(false);
  const toggleModalGallery = () => {
    setModalGallery(!modalGallery);
  };

  const [reloadImages, setReloadImages] = useState(false); // État pour recharger les images

  useEffect(() => {
    const fetchImageUrls = () => {
      axios
        .get(API_URL + `showRapportImage/${params.id}`, getApiConfig())
        .then((res) => {
          setImageUrls(res.data.urls);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    fetchImageUrls();
    // eslint-disable-next-line
  }, [reloadImages]); // Déclencher le fetch lorsque reloadImages change

  // Gestion de la sélection de plusieurs images
  function handleImageChange(event) {
    const files = Array.from(event.target.files);
    setSelectedImages(files);
  }

  const handleAddImages = async (e) => {
    e.preventDefault();
    try {
      for (const image of selectedImages) {
        const formData = new FormData();
        formData.append("image", image);

        const response = await axios.post(
          API_URL + `addRapportImage/${params.id}`,
          formData,
          getMultipartConfig()
        );

        if (response.status === 200) {
          setReloadImages((prev) => !prev); // Déclencher le rechargement des images
        }
      }
      setSelectedImages([]); // Réinitialisation de la sélection après l'upload
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteImage = async (url) => {
    try {
      const filename = url.substring(url.lastIndexOf("/") + 1);
      await axios.delete(
        API_URL + `deleteRapportImage/${params.id}/${filename}`,
        getApiConfig()
      );
      setImageUrls((prevUrls) => prevUrls.filter((prevUrl) => prevUrl !== url));
    } catch (error) {
      console.error(error);
    }
  };

    const getFileType = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) return 'image';
    if (extension === 'pdf') return 'pdf';
    return 'other';
  };

  const showSelectedImages = (url, index) => {
    const fileType = getFileType(url.name);

    if (fileType === "pdf") {
        return (
            <div key={index} className="custom-gallery-item">
                <div className="image-container">
                    PDF: {url.name.split('/').pop()}
                </div>
            </div>
        );
    }

    if (fileType === "image") {
        return (
            <div key={index} className="custom-gallery-item">
                <div className="image-container">
                    <img
                        src={URL.createObjectURL(url)}
                        alt="selected"
                        style={{
                            maxHeight: "300px",
                            maxWidth: "25vw",
                            minWidth: "90px",
                        }}
                    />
                </div>
            </div>
        );
    }

    return null; // Au cas où ce ne serait ni une image, ni un PDF
};


  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "10%",
          right: "10%",
          left: "20%",
          zIndex: "999",
        }}
      >
        {alert ? <div>{alertContent}</div> : <></>}
      </div>
      <CardBody className="mt-4 rightContainer">
        <div className="text-center">
          <Button type="submit" color="primary" onClick={toggleModalGallery}>
            Voir les photos d'installations
          </Button>
        </div>
      </CardBody>

      <Modal
        isOpen={modalGallery}
        toggle={toggleModalGallery}
        className="custom-modal-gallery"
        size="lg"
      >
        <ModalHeader
          className="justify-content-center"
          toggle={toggleModalGallery}
        >
          Photo installation
        </ModalHeader>
        <ModalBody>
          <CardBody className="rightContainer">
            <Row className="d-flex justify-content-between mr-4 ml-3">
              <h4 className="titleCardContrast">Ajouter des Images:</h4>
            </Row>
            <div className="text-center">
              <form onSubmit={handleAddImages}>
                <Row>
                  <Input
                    color="primary"
                    type="file"
                    multiple // Permet la sélection multiple
                    onChange={handleImageChange}
                  />
                  <Button color="primary" type="submit">
                    Valider
                  </Button>
                </Row>
              </form>
            </div>
          </CardBody>

          {selectedImages.length > 0 && (
            <CardBody className="mt-4 rightContainer">
              Images sélectionnées :
              <div className="custom-gallery scrollable-gallery">
                {selectedImages.map((image, index) => (
                  showSelectedImages(image, index)
                ))}
              </div>
            </CardBody>
          )}

          <CardBody className="mt-4 rightContainer">
            <div className="custom-gallery scrollable-gallery">     
                            {console.log(imageUrls, "ilmgurl")}
                
              <DocumentGallery documents={imageUrls} onDeleteDocument={handleDeleteImage} isOld={false} ></DocumentGallery>
            </div>
          </CardBody>
        </ModalBody>
      </Modal>
    </>
  );
}
