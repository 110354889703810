// src/helpers/customFilterMethod.js

const customFilterMethod = (filter, row) => {
  const id = filter.pivotId || filter.id; // Récupérer l'identifiant de la colonne
  if (row[id] == null) return true; // Si la valeur de la ligne est null ou undefined, la ligne est incluse par défaut
  const rowValue = String(row[id]).toLowerCase(); // Convertir en chaîne de caractères et mettre en minuscule
  const filterValue = String(filter.value).toLowerCase(); // Convertir la valeur du filtre en minuscule
  return rowValue.includes(filterValue); // Vérifier si la valeur de la ligne contient la valeur du filtre
};

export default customFilterMethod;
