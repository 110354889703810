import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { Card, CardBody, Row, Col } from "reactstrap";
import { CircleLoader } from "react-spinners";
import { API_URL, getApiConfig, token } from "../Config/apiConfig.js";
import {
  OLD_API_URL,
  oldGetApiConfig,
  oldToken,
} from "../Config/oldApiConfig.js";

const Maps = withScriptjs(
  withGoogleMap((props) => {
    const handleMarkerClick = (id) => {
      const url = `/showBoitier/${id}`;
      window.open(url, "_blank");
    };
    return (
      <GoogleMap
        defaultZoom={3}
        defaultCenter={{ lat: 43.64, lng: 0 }}
        defaultOptions={{
          scrollwheel: false,
          styles: [
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [{ color: "#5c7291" }, { lightness: 17 }],
            },
            {
              featureType: "landscape",
              elementType: "geometry",
              stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.fill",
              stylers: [{ color: "#ffffff" }, { lightness: 17 }],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.stroke",
              stylers: [
                { color: "#ffffff" },
                { lightness: 29 },
                { weight: 0.2 },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "geometry",
              stylers: [{ color: "#ffffff" }, { lightness: 18 }],
            },
            {
              featureType: "road.local",
              elementType: "geometry",
              stylers: [{ color: "#ffffff" }, { lightness: 16 }],
            },
            {
              featureType: "poi",
              elementType: "geometry",
              stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [{ color: "#dedede" }, { lightness: 21 }],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                { visibility: "on" },
                { color: "#ffffff" },
                { lightness: 16 },
              ],
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                { saturation: 36 },
                { color: "#333333" },
                { lightness: 40 },
              ],
            },
            { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
            {
              featureType: "transit",
              elementType: "geometry",
              stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
            },
            {
              featureType: "administrative",
              elementType: "geometry.fill",
              stylers: [{ color: "#fefefe" }, { lightness: 20 }],
            },
            {
              featureType: "administrative",
              elementType: "geometry.stroke",
              stylers: [
                { color: "#fefefe" },
                { lightness: 17 },
                { weight: 1.2 },
              ],
            },
          ],
        }}
      >
        {props.location.map((location, index) => (
          <Marker
            key={index}
            position={{ lat: location.lat, lng: location.lng }}
            onClick={() => handleMarkerClick(location.id)}
          />
        ))}
      </GoogleMap>
    );
  })
);

export default function TabDashboard() {
  const [loading, setLoading] = useState(true);
  const [marker, setMarker] = useState([]);

  const filterAndMapData1 = (data) => {
    const filteredData = data
      .filter(
        (m) =>
          m.lat !== null &&
          m.lat !== undefined &&
          m.lng !== null &&
          m.lng !== undefined
      )
      .map((m) => ({ id: m.id, lat: m.lat, lng: m.lng }));
    console.log("Filtered Data 1:", filteredData);
    return filteredData;
  };

  const filterAndMapData2 = (data) => {
    const filteredData = data
      .filter(
        (m) =>
          m.adresse &&
          m.adresse.location &&
          m.adresse.location.lat !== null &&
          m.adresse.location.lat !== undefined &&
          m.adresse.location.lng !== null &&
          m.adresse.location.lng !== undefined
      )
      .map((m) => ({
        id: m.id,
        lat: m.adresse.location.lat,
        lng: m.adresse.location.lng,
      }));
    console.log("Filtered Data 2:", filteredData);
    return filteredData;
  };

  useEffect(() => {
    const fetchData = async () => {
      let markers = [];

      if (oldToken && token) {
        try {
          const [res1, res2] = await axios.all([
            axios.get(API_URL + "showAllPosition", getApiConfig()),
            axios.get(
              OLD_API_URL + "boitier/getBoitierLocationList",
              oldGetApiConfig()
            ),
          ]);

          console.log("API 1 Response:", res1.data);
          console.log("API 2 Response:", res2.data.data);

          const filteredData1 = filterAndMapData1(res1.data);
          const filteredData2 = filterAndMapData2(res2.data.data);

          markers = [...filteredData1, ...filteredData2];
        } catch (error) {
          console.error("Error fetching data from both APIs:", error);
        }
      } else if (token) {
        try {
          const res1 = await axios.get(
            API_URL + "showAllPosition",
            getApiConfig()
          );
          console.log("API 1 Response:", res1.data);
          markers = filterAndMapData1(res1.data);
        } catch (error) {
          console.error("Error fetching data from new API:", error);
        }
      } else if (oldToken) {
        try {
          const res2 = await axios.get(
            OLD_API_URL + "boitier/getBoitierLocationList",
            oldGetApiConfig()
          );
          console.log("API 2 Response:", res2.data.data);
          markers = filterAndMapData2(res2.data.data);
        } catch (error) {
          console.error("Error fetching data from old API:", error);
        }
      }

      setMarker(markers);
      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Row>
      <Col xs={12} md={12}>
        {/* <Card> */}
        <CardBody className="mapContainer rightContainer">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              height: "400px",
            }}
          >
            {loading ? (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircleLoader color={"#3B90C8"} />
              </div>
            ) : (
              <Maps
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCScwQTSy0tjIeKghvHGirI2xxltC5pzAk`}
                loadingElement={
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircleLoader color={"red"} />
                  </div>
                }
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                location={marker}
              />
            )}
          </div>
        </CardBody>
        {/* </Card> */}
      </Col>
    </Row>
  );
}
